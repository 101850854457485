import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/sale-banner.jpg`;
const amexBanner = `${settings.IMAGES_BASE_URL}/images/specialoffers/amex-sale.jpg`;

const SpecialOffersAMEX = () => {
  const getFirstCard = () => {
    const today = new Date();
    const novPromotions =
      today >= new Date(2024,0,12) &&
      today < new Date(2024,0,31);
    if (novPromotions) {
      return (
        <>
          <FlexCard
            rowType="row"
            image={amexBanner}
            imageAlt="GET A FASTER START TO THE YEAR."
            header="GET A FASTER START TO THE YEAR."
            subheader="GET 20% OFF MOST DRIVING SCHOOL CLASSES."
            text="Jump off the line fast in 2024. Get 20% off most classes booked from 1/15/24 - 1/21/24."
            btnText="Call 888-345-4269 to book now"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
          >
            <p>
              Use promo code <span className="bold">24AMEX20</span>. Must purchase between 1/15/24 and 1/21/24. M4 GT4 Experience, Race License School and Private Instruction excluded. Valid in California location only.
            </p>
          </FlexCard>
          <br />
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage giftcardpageretargeting">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {getFirstCard()}
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersAMEX;
